import { create } from "@storybook/theming/create";

export default create({
  base: "dark",
  // Typography
  fontBase: '"Roboto", sans-serif',
  fontCode: "monospace",

  brandTitle: "MOTHER",
  brandUrl: "https://mothershealty.eu/",
  brandImage: "../public/mother.png",
  brandTarget: "_self",

  // Colors
  colorPrimary: "#0153d3",
  colorSecondary: "#0153d3",

  // UI
  appBg: "#1b1c1b",
  appContentBg: "#2C2D2C",
  appBorderColor: "#2C2D2C",
  appBorderRadius: 4,

  // Text colors
  textColor: "#d1d1d1",
  textInverseColor: "#d1d1d1",

  // Toolbar colors
  barTextColor: "#d1d1d1",
  barSelectedColor: "#ffff",
  barBg: "#2C2D2C",

  // Form colors
  inputBg: "#2C2D2C",
  inputBorder: "#d1d1d1",
  inputTextColor: "#d1d1d1",
  inputBorderRadius: 2,
});
